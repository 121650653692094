<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M26 39.35c7.373 0 13.35-5.977 13.35-13.35 0-7.373-5.977-13.35-13.35-13.35-7.373 0-13.35 5.977-13.35 13.35 0 7.373 5.977 13.35 13.35 13.35z" stroke="#000" stroke-width="1.3"/><path d="M26 20v12M20 26h12" stroke="#000" stroke-width="1.3" stroke-linecap="round"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionAdd'
  };
</script>
