<template>
  <div class="list-group">
    <Banner>
      <div class="banner-content">
        <MeepIconSettings class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.setting") }}</span>
      </div>
    </Banner>
    <PageHeader
      v-model="query"
      :has-actions="true"
      :has-back="true"
      :has-search="true"
      searchBesideAction
      title="Groupes"
      @back="goBack"
    >
      <md-button
        v-if="isAdmin"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="$router.push('/dashboard/setting/groupes/create')"
      >
        <MeepIconActionAdd class="app-icon-stroke-inverted" />
      </md-button>

      <md-button
        v-if="isAdmin && selectedList.length > 1"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        class="md-raised header-action header-action-icon"
        @click="isConfirmModalOpen = true"
      >
        <MeepIconActionDelete class="app-icon" />
      </md-button>
    </PageHeader>
    <div class="list-group-content page-layout">
      <!-- La liste des groupes -->
      <sortable-list
        :has-pagination="true"
        :items="filter"
        :parameters="listParameters"
        :selectable="isAdmin ? 'multiple' : 'single'"
        class="list-group__list"
        default-sort="name"
        default-sort-order="desc"
        link="/dashboard/setting/groupes/view/"
        @selected="onSelect"
        @item-click="onSingleDelete"
      >
      </sortable-list>

      <ConfirmActionModal
        v-if="isConfirmModalOpen"
        :object-to-act-upon="selectedList"
        :text="confirmDeleteModalText"
        @close="isConfirmModalOpen = false"
        @confirm="onDelete"
      />
    </div>
  </div>
</template>

<script>
import groupesModel from "../../model/groupes";
import { mapGetters } from "vuex";
import ConfirmActionModal from "../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";
import MeepIconActionAdd from "@/components/icons/MeepIconActionAdd.vue";
import MeepIconActionDelete from "@/components/icons/MeepIconActionDelete.vue";
import Banner from "@/components/Banner";
import MeepIconSettings from "@/components/icons/MeepIconSettings.vue";

import Fuse from "fuse.js";

export default {
  name: "ListGroup",

  components: {
    PageHeader,
    ConfirmActionModal,
    MeepIconActionAdd,
    MeepIconActionDelete,
    Banner,
    MeepIconSettings,
  },

  data() {
    return {
      query: "",
      groupes: [],
      selectedList: [],
      isConfirmModalOpen: false,

      confirmDeleteModalText: {
        header: "Suppression",
        body(object) {
          return (
            "Vous êtes sur le point de supprimer définitivement <b>" +
            object.length +
            " élément" +
            (object.length > 1 ? "s.</b>" : ".</b>")
          );
        },
        question: "Êtes vous sûr de vouloir le faire ?",
      },
    };
  },

  computed: {
    ...mapGetters(["isAdmin", "isJEP"]),

    listParameters() {
      const defaultParams = [
        {
          name: "Nom ",
          key: "name",
        },
        {
          name: "Date de création",
          key: "created_at",
          format: this.$$filters.formatTimestamp,
        },
      ];

      if (this.isAdmin) {
        defaultParams.push({
          name: "",
          isIcon: true,
          iconFormat: () => "delete",
          iconClass: () => "",
          clickable: true,
          width: "50px",
        });
      }

      return defaultParams;
    },

    filter() {
      if (this.query === "") {
        return this.groupes;
      }

      const fuse = new Fuse(this.groupes, {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["name"],
      });

      return fuse.search(this.query).map(item => item.item);
    },
  },

  async mounted() {
    this.groupes = await groupesModel.getAll();
  },

  methods: {
    goBack() {
      window.history.back();
    },

    onSelect(items) {
      this.selectedList = items;
    },

    async onDelete() {
      try {
        await Promise.all(
          this.selectedList.map(async item => {
            await groupesModel.remove(item.id);

            this.$toasted.global.AppSucces({
              message: "Le groupe " + item.name + " a bien été supprimé",
            });

            this.groupes = this.groupes.filter(group => group.id !== item.id);
            this.selectedList = this.selectedList.filter(
              listItem => listItem.id !== item.id,
            );
          }),
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    onSingleDelete({ item }) {
      if (this.selectedList.length <= 1) {
        this.selectedList = [{ ...item }];
      }
      this.isConfirmModalOpen = true;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";
#app {
  .list-group {
    &__list {
      width: 100%;
    }
    .page-header {
      .page-header__item {
        margin-bottom: 0;
        align-items: flex-start;
        .page-header__actions {
          .page-search{
            min-width: unset;
            max-width: 450px;
            width: 100%;
          }
        }
      }
    }
    &-content {
      &.page-layout {
        height: 100%;
        margin-top: toRem(15);
        @include for-lg{
        margin-top: toRem(30);}
      }
    }
  }
}
</style>
