var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-group"},[_c('Banner',[_c('div',{staticClass:"banner-content"},[_c('MeepIconSettings',{staticClass:"banner-content__icon"}),_c('span',{staticClass:"banner-content__text"},[_vm._v(_vm._s(_vm.$t("menu.setting")))])],1)]),_c('PageHeader',{attrs:{"has-actions":true,"has-back":true,"has-search":true,"searchBesideAction":"","title":"Groupes"},on:{"back":_vm.goBack},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}},[(_vm.isAdmin)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":function($event){return _vm.$router.push('/dashboard/setting/groupes/create')}}},[_c('MeepIconActionAdd',{staticClass:"app-icon-stroke-inverted"})],1):_vm._e(),(_vm.isAdmin && _vm.selectedList.length > 1)?_c('md-button',{staticClass:"md-raised header-action header-action-icon",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":function($event){_vm.isConfirmModalOpen = true}}},[_c('MeepIconActionDelete',{staticClass:"app-icon"})],1):_vm._e()],1),_c('div',{staticClass:"list-group-content page-layout"},[_c('sortable-list',{staticClass:"list-group__list",attrs:{"has-pagination":true,"items":_vm.filter,"parameters":_vm.listParameters,"selectable":_vm.isAdmin ? 'multiple' : 'single',"default-sort":"name","default-sort-order":"desc","link":"/dashboard/setting/groupes/view/"},on:{"selected":_vm.onSelect,"item-click":_vm.onSingleDelete}}),(_vm.isConfirmModalOpen)?_c('ConfirmActionModal',{attrs:{"object-to-act-upon":_vm.selectedList,"text":_vm.confirmDeleteModalText},on:{"close":function($event){_vm.isConfirmModalOpen = false},"confirm":_vm.onDelete}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }